
#wbcMenu .tool:hover {
    max-width: 100%;
    transition: .2s;
}

#wbcMenu {
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-size: 16px;
    border-radius: 0;
    position: fixed;
    margin-bottom: 30px;
    left: 0;
    top: 0;
    color: black;
    max-height: 100%;
    transition-duration: 1s;
    cursor: default;
    padding: 10px;
    pointer-events: none;
}

#wbcMenu .tool {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: auto;
    white-space: nowrap;
    list-style-position: inside;
    border: 1px solid #eeeeee;
    text-decoration: none;
    cursor: pointer;
    background: #ffffff;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 0px;
    max-width: 42px;
    transition-duration: .2s;
    overflow: hidden;
    width: max-content;
    box-shadow: inset 0 0 3px #8fa2bc;
}

#wbcMenu  .tool-icon {
    display: flex;
    text-align: center;
    width: 32px;
    height: 32px;
    margin: 2.5px;
    font-family: Lato !important;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.wbcDrawer .MuiPaper-root {
    overflow: hidden;
    border: 0px;
    background-color: transparent;
}

#wbcMenu  .tool-name, #wbcMenu  .tool-name span {
    text-align: center;
    color: black !important;
    font-size: 23px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
    margin-left: 10px;
}


#wbcMenu .MuiListItem-root.Mui-selected{
    box-shadow: 0 0 5px #0074d9;
    background: linear-gradient(#96E1FF, #36A2FF);
}

.colorICBtn{
    border-radius: 0px !important;
    min-width: 30px;
    margin: 4px !important;
}