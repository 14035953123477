.dialogTitle{
    font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 20px;
color: #1E2639;
}
.closeButton {
    position: absolute;
    right: 35px;
    top: 20px;
    background: #D3D9DE;
    border-radius: 999px;
    cursor:pointer;
}
.headerbg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #FFFFFF !important;
}
.headerbg .MuiIconButton-root{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 32px;
    top:16px;
}
.datapicker {
    width: 280px;
    height: 40px;
    font-size: 16px;
    border-radius: 999px;
    padding: 0px 20px;
    color:#B3BDC5;
    outline:none;
    background: #FFFFFF;
    border: 1px solid #E0E5E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 35, 61, 0.04);
}
.headerbg .MuiIconButton-label{
     color: #8E929C;
}
.formRadio .MuiRadio-colorSecondary.Mui-checked {
   color: #3174F5;
}
.formRadio .MuiTypography-body1{
     text-transform: capitalize;
     font-size: 14px;
}
.formControls .MuiFormControlLabel-labelPlacementStart {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px !important
}
.formControls .MuiTypography-body1 {
    text-transform: capitalize;
    font-size: 14px;
}
.formControls .MuiSwitch-sizeSmall{
    margin-right: 10px;
}
.formControls .MuiSwitch-colorSecondary.Mui-checked {
    color: #3174F5;
}
.formControls .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color:  #79a6fa;
}
.nameDialog .MuiPaper-root{
    min-width: 35vw;
    max-width: 480px;
    height: auto;
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
}
.nameEnter{
    border-radius: 36px;
    width: 60%;
    padding:4px 20px;
    align-self: center;
    background: #FFFFFF;
    border: 1px solid #E0E5E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 35, 61, 0.04);
    border-radius: 999px;
    width:300px;
}
.timerSetting{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tipsDialog .MuiPaper-root{
    border-radius: 4px !important;
    min-width: 35vw;
    max-width: 35vw !important;
    box-shadow: none !important;
    box-sizing: border-box;
    height: fit-content;
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16);

}

.tipsDialog .MuiDialogContent-root{
    height: 400px;
}

.inpSearch{
    background: #F8F9FB;
    border-radius: 36px;
    width: 100%;
}

.inpSearch .MuiInput-underline:before, .inpSearch .MuiInput-underline:after{
    border: none !important;
}

.primaryContent{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #2F3D5B !important;
}
.secondaryContent{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #495769 !important
}
.inpSearch input{
    padding: 12px 0 !important;
}

.tipsDialog .MuiBackdrop-root{
    opacity: 0 !important;
}

@media only screen and (max-width: 600px) and (min-width: 310px) {
    .tipsDialog .MuiPaper-root{
        min-width: 100% !important;
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        border: none !important;
    }
    .tipsDialog .MuiDialogContent-root{
        height: 250px;
    }
    .headerbg{
        background: #D9FFA2;
        border: 0.5px solid #71C001;
        box-sizing: border-box;
        border-radius: 12px 12px 0px 0px;
}
.shareDialog .MuiPaper-root {
    width: 100% !important;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    max-height: 100% !important;
    height: 90% !important;
    }
    .qrcode{
        width: 150px !important;
        margin: 10px 24px 10px 30px !important;
    }
}

.homebtn{
    color: #ffffff !important;
    display: inline-flex !important;
    font-size: 0.5rem !important;
    font-family: Poppins !important;
    font-weight: normal !important;
    border-radius: 5px !important;
    text-transform: none !important;
    background-color: #3174F5 !important;
    margin: 5px !important;
}
.resetButton{
    color: #ffffff !important;
    font-size: 0.5rem !important;
    font-family: Poppins !important;
    font-weight: normal !important;
    border-radius: 5px !important;
    text-transform: none !important;
    background-color: #3174F5 !important;
    margin: 10px !important;
}

.shareDialog .MuiPaper-root {
width: 400px;
height: fit-content;
    overflow: hidden;
}
.scanContent{
    font-family: Helvetica Now Display;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
color: #2F323A;
}

.shareByTxt{
    font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 276.19%;
text-align: center;
color: #71C001;
}

.alignCenter{
    text-align: center !important;
    margin: 0 20% 10px!important;
}
.sharebyUrlBox{
    background: #D7E4EB;
    /* height: 100%; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
}

.sharebyUrlBox .MuiInput-underline:before, .sharebyUrlBigBox .MuiInput-underline:before{
    border: none !important;
}
.sharebyUrlBox .MuiInputBase-root{
    width: 80% !important;
    margin: auto;
    background: white;
} 

.sharebyUrlBox  .MuiInputAdornment-positionStart, .sharebyUrlBigBox .MuiInputAdornment-positionStart{
    margin-right: 0 !important;
}
.sharebyUrlBox  .MuiInputAdornment-positionStart button, .sharebyUrlBigBox .MuiInputAdornment-positionStart button  {
padding: 0% !important;
}
.click2copy{
    font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 161.19%;
text-align: center;
color: #2F323A;
margin: 4px 0;
}

.sharebyUrlBigBox .MuiInputBase-root {
    margin: auto;
    width: 88%;
    background: #F7F9FA;
} 

.sharebyUrlBigBox .MuiFormControl-root {
    width: 100%;
    margin: 10px 0;
}
.click2Email{
    text-decoration: none !important;
    background: #F7F9FA;
}

.start-btn{
    padding: 6px 8px;
    min-width: 64px;
    box-sizing: border-box;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
}

.flex-column{
    flex-direction: column;
}
.flex-align-center{
    align-items: center;
}

.active{
    color: #ffffff !important;
}

.mr-4{
    margin-right: 4px;
}

.hambBtn{
    padding: 5px !important;
    min-width: fit-content !important;
    padding: 4px;
    overflow: visible;
    font-size: 0.75rem !important;
    text-align: center !important;
}
 
/* style for scrollbar */
*::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

  *::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    width: 6px;
    background-color: rgba(64, 83, 123, 0.1) !important;
}

*::-webkit-scrollbar-track {
    background: white;
}

/* style for board title in manageboard */
.BoardTitleContent{
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* more button in GridView */
.moreBtn{
    display: flex;
    align-items: center;
}

.customFavIcon {
    margin: 0px;
    padding: 0px;
    color: "#ffffff";
    margin-left: auto;
}

.customFavIcon:hover {
    color: white !important;
}

.customMenuItem {
    color: "#ffffff"
}

.customMenuItem:hover {
    background-color: rgba(0, 0, 0, 0.3) !important;
}
.undoPanel{
    display: flex !important;
    align-items: center;
}

.btnStyle{
    color: #FFFFFF !important;
    font-size: 16px !important;
    cursor: pointer !important;
    border-radius: 999px !important;
    font-family: Lato !important;
    font-weight: normal !important;
    padding: 6px 12px !important;
    text-transform: capitalize !important;
    letter-spacing: -0.078px !important;
}
.Helplist{
    font-family: Poppins !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    letter-spacing: -.01em !important;
    flex: none !important;
    order: 0 !important; 
    flex-grow: 0 !important;
    color: #6b6b7d !important;
    text-align: center;
}

.posAbsolute{
    position: absolute !important;
}

.newheader2{
    margin: 20px 30px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.logoBtn{
    position: relative;
    top: 4vh;
    left: 3vw;
} 
@media only screen and (max-width: 1080px) and (min-width: 540px) {
    .inputBaseFiled .MuiInputBase-input {
        font-size: 12px;
    }
}