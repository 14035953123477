$color-red:                   #e8543f;
$color-blue:                  #3da3db;
$color-grey:                  #707275;
$color-grey-dark:             #b4b1af;
$color-grey-light:            #eceff1;
$color-black:                 #72869F;
$color-grey-light:            #eceff1;
$color-white:                 #ffffff;
$color-black:                 #72869F;
$color-lightteal:             #63BBBD;
$color-olive:                 #8AAF60;
$color-purple:                #8C3096;

$color-grey-darkest:          #46474b;
$color-green:                 #3ddb88;
$color-yellow:                #ebb531;

// $color-primary:               #3bed98;
$color-primary:               #f2530b;
$color-secondary:             $color-blue;

$color-catenary:              $color-black;

$color-interface-grid:        $color-black;

$color-debug-grid:            $color-grey-darkest;
$color-debug-lazy:            $color-grey-darkest;
$color-debug-lazy-active:     $color-grey;
$color-debug-brush:           $color-grey;
$color-debug-pointer:         $color-black;
$color-bg:                    #ffffff;
$color-tools:                 #E5E5E5;

$fa-font-path:                "../webfonts";
