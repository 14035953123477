body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

@font-face {
  font-family: 'Chewy';
  src: local('Chewy'), url(./fonts/Chewy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'KgTeacherHelpers';
  src: local('KgTeacherHelpers'), url(./fonts/KgTeacherHelpers-q4J1.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-display: block;
  src: local('OpenDyslexic'), url(./fonts/OpenDyslexic-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Sassoon';
  font-display: block;
  src: local('Sassoon'), url(./fonts/SassoonPrimaryStd.otf) format('opentype');
}
@font-face {
  font-family: 'Comic Sans';
  font-display: block;
  src: local('Comic Sans'), url(./fonts/ComicSansMS3.ttf) format('truetype');
}


@font-face {
  font-family: 'Lexend';
  font-display: block;
  src: local('Lexend'), url(./fonts/Lexend-Regular.ttf) format('truetype');
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mb-30{
  margin-bottom: 30px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.ml-5{
  margin-left: 5% !important;
}

.mt-10{
  margin-top: 10px !important;
}

.mt-5perc{
  margin-top: 5% !important;
}


#root, #root > div{
  height: 100%;
}

.h-full{
  height: 100% !important;
}

.justify-content{
  justify-content: center !important;
}

.disp-inline-block{
  display: inline-block !important;
}
.w-full{
  width: 100% !important;
}

.ml-8{
  margin-left: 8px !important;
}

.mr-10{
  margin-right: 10px !important;
}

.justify-between{
  -webkit-box-pack: justify;
  justify-content: space-between !important;
}

.p-16{
  padding: 16px !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.listBorder{
  border: 0.5px solid #D7E4EB;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.shadow-none {
  box-shadow: none
}

.rounded-8 {
  border-radius: .8rem
}

.cursor-pointer{
  cursor: pointer;
}

.pt-0 {
  padding-top:0 !important;
}

.mt-16{
  margin-top: 16px !important;
}

.mr-8{
  margin-right: 8px !important;
}
.pl-30{
  padding-left: 30px;
}
.rounded-0 {
  border-radius: 0 !important
}

.mt-24{
margin-top: 24px !important;
}

.m-0{
  margin: 0 !important;
}
.m-auto{
  margin: auto !important;
}

.ml-auto{
  margin-left: auto !important;
}

.mr-auto{
  margin-right: auto !important;
}

.my-10{
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}