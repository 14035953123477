body{
    background-color: #f3f8fe !important;
}

.kiddieFrameOuterNd{
    background-color: transparent;
    border: none !important;
}
.kiddieFrameInnerNd{
    border: none !important;
}
.canvContainer{
    background-color: transparent !important;
    height: 100% !important;
    overflow: hidden;
}
.canvContainer #canvas_drawing{
    border-radius: 6px;
    background-color: #FFFFFF;
  }

/* minidrawer css */
.wcsection{
    background-color: white !important;
    border-radius: 50px;
    padding: 6px 25px;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #526A9E !important;
}

.wcChip{
    display: flex;
    align-items: center;
    /* height: 60px;
    width: 254px; */
    height: 50px;
    width: 250px;
    padding: 5px;
    justify-content: space-evenly;    
    /* position: absolute;
    top: 2px;
    left: 32px; */
    margin: 24px !important;

}
.chip2{
    background-color: white !important;
    /* border-radius: 50px; */
    padding: 6px 25px;  
    height: 50px;
    width: fit-content; 
    display: flex;
    align-items: center;
    justify-content:space-evenly;    
    margin: 24px !important;
    z-index: 0;
}
/* for now hiding as the design does not have this thing */
/* .undoredo_nav{
    display: none !important;
} */
.chip2 #inviteButton{
    border-radius: 50px;
    height: 30px;
    width: 100px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.rigtSideBar{
    width: fit-content;
    position: fixed;
    right: 0;
    top: 96px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25), -8px 0px 8px 2px rgba(13, 20, 22, 0.02);
}

.rtArrowBtn{
    padding: 0 !important; 
    position: relative !important;
    right: 18px;
    top: 32px;
}
.ltArrowBtn{
    padding: 0 !important;
    top: 32px;
    width: fit-content;
    position: fixed !important;
    right: 300px;
}

.ddtitle{
    padding: 32px 0px !important;
    margin: 0 auto !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #40537B !important;
}


.handle .MuiInput-underline:hover:not(.Mui-disabled):before , .handle .MuiInput-underline:before, .handle .MuiInput-underline:after{
    border: none !important;
}

.palBtn{
    width: 98%;
    margin: auto !important;
    height: 35px !important;
    border-radius: 50px;
    background: #FFFFFF;
    border: 1px solid #E2E6E9;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 8px 2px rgb(13 20 22 / 2%);
    text-align: center;
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
}

.palBtn .MuiSelect-select{
    width: fit-content;
    padding-right: 11px !important;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 100%;
display: flex;
align-items: center;
text-align: center;
font-feature-settings: 'ss04' on;
color: #40537B;
}
.palBtn .MuiSelect-select:focus{
    background-color: transparent !important;
}
.searchBar{
    display: flex;
    justify-content: center;
}
.searchBar .MuiFormControl-root{
    width: 73%;
}
.overFlowList{
    overflow: auto;
    width: 320px;
}
.dragBtn{
    padding: 0 !important;
    width: fit-content !important;
    cursor: grab !important;
}

.bgTrans{
    background: transparent !important;
}

.GridRowContiner{
    margin-top: 2% !important;
}

.rectBoard{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 2px rgba(13, 20, 22, 0.02) !important;
    border-radius: 6px !important;
    border: none !important;
}

.dropdownStyle .MuiMenu-list .MuiListItem-root {
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 100%;
align-items: center;
color: #40537B;
}

.listItems .MuiTypography-body1{
    font-family: Lato !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 100% !important;
letter-spacing: 0.08em !important;
color: #40537B;
}


.dropdownStyle{
    width: 240px;
    background: #FFFFFF ;
    box-shadow: 0px 2px 4px 2px rgba(13, 20, 22, 0.08), 0px 0px 8px 2px rgba(13, 20, 22, 0.02) !important;
    border-radius: 8px !important;
    margin-top: 36px;
    left: auto !important;
    right: 35px;
}

.pd-5{
    padding: 5px !important;
}
.transBg *::-webkit-scrollbar-track{
    background-color: transparent;
}

.dragDiv{
    border: 0.5px solid rgba(0, 0, 0, 0.08) !important;
    box-sizing: border-box;
    width: 300px !important;
    background-color: #FFFFFF !important;
}
.dragDivmain{
    background-color: transparent !important;
    color: black;
}
.dragDivChild1{
    width: 95% !important;
    height:7px;
    background-color: #E2E6E9 !important ;
}

.dragDivChild2{
    width: 90%;
    margin: 10px !important;
}
.dragDivform{
    margin: 10px 10px !important;
    width: 90% !important;
}
.dragDivForms{
    width: 90% !important;
    position: absolute;
    top: 40px;
    left: 14px;
}
.dragDivForm, .dragDivForm input {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.078px !important;
    color: rgba(82, 106, 158, 0.9) !important;
}
.dragDivFormText .MuiTypography-body2 {
    font-size: 16px;
}
.frmlblStyle .MuiTypography-root{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: -0.078px !important;
    color: #2F3D5B !important; 
    text-transform: capitalize !important;
    margin:4px 6px !important;
} 
.frmlblStyle .MuiCheckbox-colorPrimary.Mui-checked {
    color: #3174f5;
}


.dragContainer{
    font-family: Lato !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 14px !important;
line-height: 20px !important;
letter-spacing: -0.078px !important;
color: rgba(82, 106, 158, 0.5) !important;
}

.formBtns{
    align-items: center;
    text-align: right;
}

.formCancel{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12.5px;
    line-height: 15px;
    text-align: center;
    color: #55677D;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
}

.formConfirm .MuiButton-label{
    font-family: Lato !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 12.5px !important;
line-height: 15px !important;
text-align: center !important;
color: #FFFFFF !important;
text-transform: capitalize !important;
}

.wcDialog .MuiDialog-container .MuiPaper-root{
    height: 374px;
}

.posBtnOfGridBrowser > button{
    color: black !important;
    position: relative;
    top: 10px;
}

.newContent{
    font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 100%;
display: flex;
align-items: center;
color: #00233D;
}

.weHeader{
    display: flex;
    justify-content: flex-end;
}

.weHeader img{
    position: relative;
    top: 40px;
    left: 120px;
}
.welComeText, .invtContent{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 100% !important;
    display: flex;
    align-items: center;
    color: #00233D;
    padding: 10px 0 !important;
    margin: 15px 0 !important;
}
.mainBtns{
    width: 334px !important;
    height: 40px !important;
    background: #3174F5 !important;
    border: 1px solid #1F68F4 !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    margin: 0 120px 0px 120px !important;
}

.mainBtns .MuiButton-label{
    font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 100%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.02em;
font-feature-settings: 'ss04' on;
color: #FFFFFF !important;
text-transform: capitalize;
}

.wcContent{
    padding: 8px 0 !important;
    overflow: hidden !important;
}

.signInlbl, .font14px{
    font-family: Lato !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 14px !important;
line-height: 100% !important;
display: flex !important;
align-items: center !important;
text-align: right !important;
color: #00233D;
margin: auto !important;
}
.singInLink{
    padding: 4px; 
    color:#3174f5;
    cursor: pointer;
}

.joinContent{
    padding: 0 5px 5px;
    font-family: Lato !important;
font-style: normal !important;
font-weight: normal !important;
font-size: 12px !important;
line-height: 20px !important;
text-align: center !important;
color: #00233D !important;
margin: 0px 8px !important;
}
.joinTeacherContent{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #6B7280 !important;
    margin: 0px 8px !important;
}

.joinContainer{
    display: flex;
    margin: 16px 120px 0 !important;

}
.joinBtn{
    width: 90px !important;
    margin: 12px 10px !important;
    float: right;
}
.dispFlexEnd{
    display: flex !important;
    justify-content: flex-end;
}

.joinSection .joinCodeInput {
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 100% !important;
    font-feature-settings: 'ss04' on;
    color: #B3BDC5;
    padding: 10px 2vw;
}

.lato12px{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-align: center !important;
    color: #6B7280;
}

.joinSection .Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: none !important;
}
.newDesignsd  .MuiDialog-container .MuiPaper-root{
    width: 500px !important;
    height:500px !important;
    overflow-y: scroll;
}

.newDesignsd .weHeader img{
    position: relative;
    top: 10px;
    right: 10px;
}
.ndCloseIcon{
    position: relative;
    right: 28px;
    top: 27px;
    cursor: pointer;
    color: #40537B;
    background-color: #D3D9DE ;
    border-radius: 999px;
}

.flexCenter{
    display: flex;
    justify-content: center;
} 
.newDesignsd .qrcode{
    margin: 8px !important;
    margin-top: 0 !important;
}

.mb-24{
    margin-bottom: 24px !important;
}

.joinInp{
width: 250px !important;
}
.joinInp .MuiOutlinedInput-input{
    width: 250px !important;
    background: linear-gradient(270deg, #F6F8FB 17.19%, rgba(246, 248, 251, 0) 35.42%) !important;
    border-radius: 50px !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 100% !important;
    text-align: center;
    font-feature-settings: 'ss04' on;
    color: #6B7280 !important;
    padding: 10px !important;
}

.joinInp fieldset{
    border: none;
}

.joinInp .MuiInputBase-root{
    border-radius: 50px !important;
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
}

.urlCopyBtn{
    width: 60px !important;
    height: 40px !important;
    border: 1px solid #D1D5DB !important;
    box-sizing: border-box !important;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 5%) !important;
    border-radius: 50px !important;
}
.inviteICon {
    padding: 20px 0px 10px 10px !important;
}
.inviteICon:hover {
    cursor: text !important;
}

.flex-a-c{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.gClassRoom{
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
    border-radius: 999px;
    display: flex;
    width: 150px;
    height: 40px;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px;
    margin: 0 4px;
}
.clsTitle{
    margin-right:21px;
    margin-left: 8px;
}
.gClassRoom div{
    display: flex;
}

.shareBtns{
    display: flex;
    justify-content: center;
    position: relative;
    top: 16px;
}
.shareSection2{
    margin: 0 auto;
}
.elink{
    margin-top: 10px !important;
}

.codeMono .MuiOutlinedInput-input{
    font-family: monospace !important;
}

.undoNewCls{
   left: 0px !important;
   padding: 0 0 0 65px !important;
}
.undoNewMove{
    left: 10px !important;
    padding: 0 0 0 18px!important;
}
.canvMove{
    left: 150px !important;
}
/* .signInDialog .MuiDialog-container .MuiPaper-root{
    width: 540px !important;
    height: 540px !important;
} */
.newClsAuthD{
    width: 540px !important;
}

.newClsAuthD form .MuiFormControl-root{
    width: 100% !important;
}

.newClsAuthD .login, .newClsAuthD .optin{
    width: 100% !important;
} 
.logoContainer{
    margin-top: 24px;
    display: flex;
}

.newClsAuthD .Content{
    margin: 24px 0 !important;
    padding: 0 !important;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #00233D;
}
.ml-20{
    margin-left: 20px !important;
}
.bgTrans #OutermostGrid{
margin-left: 40px !important;
}

.playIconBtn{
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    margin: auto !important;
}
.menuIconCls{
    display: flex;
    justify-content: center;
}
.menuIconCls button{
color: #FFFFFF;
}
.inpTextProps input{
    color:rgba(82, 106, 158, 0.8) !important;
}
.ffHelp{
    margin: 10px 10px 10px !important;
    width: 100% !important;
    margin-left: 30px;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    letter-spacing: -0.078px !important;
    color: #2F3D5B !important;
    display: flex;
    flex-direction: column;
}

.dragDivWidth{
width: fit-content !important;
}
.forTxtArea textarea{
    height: 80px !important;
    font-size: 14px !important;
    line-height:18px;
    padding-left: 0px;
    padding-top: 8px;
}
.rtBorder{
    border-right: 1px solid #eee;
}
.flexJustifyBetween{
    display: flex;
    justify-content: space-between;
}
.chatlng{
    padding: 10px;
}
.tileFactContainer .MuiCheckbox-root{
    padding: 4px !important;
}
.tfHelp{
    width: 90% !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    letter-spacing: -0.078px !important;
    color:#2F3D5B !important;
    display: flex;
    flex-direction: column;
}
.tileTxtArea textarea{
    font-size: 14px !important;
    height: 150px !important;
    line-height: 100%;
    margin-left: 4px;
    padding-left: 0px;
    padding-top: 8px;
}

.TileChkBoxes{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    color:#2F3D5B;
    text-transform:lowercase;
}
.TileChkBoxes .MuiFormControlLabel-root{
 text-transform: capitalize;
}

.dragDivForm .sketch-picker{
margin: auto;
box-shadow: none !important;
}
.backBtns{
    margin: 5px 20px 0 0;
    width: 100%;
}

.saveDiagChkBxs .MuiCheckbox-root{
padding: 4px;
}
.saveDiagChkBxs .MuiFormControlLabel-root{
    margin: 0 5px 0 0 !important;
}
.m-10{
    margin: 10px;
}
.disAblBtnBg{
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.gridPanel{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 8px;
}
.gridPanel .MuiFormControlLabel-root{
    width: 70%;
}

.headerBtnPanel {
    align-items: center;
    height: 60px !important;
}
.oldHeader{
    background-color: transparent !important;
    width: fit-content !important;
}

.newOpts button{
height: fit-content;
padding: 0;
}

.invChip{
    margin: 24px 24px 24px 0 !important;
    padding: 6px 25px 6px 0 !important;
}
.shrBtnlbl{
    cursor: pointer;
    position: relative;
    left: -8;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    font-feature-settings: 'ss04' on;
    color: #6B7280;
}
.click2Email{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #00233D;
}

@media only screen and (max-width: 600px) and (min-width: 310px) {
.ndCloseIcon{
    right: 50px;
}
.welComeText, .invtContent{
    padding: 8px 0 !important;
    margin: 5px 0 !important;
}
.newDesignsd .MuiDialog-container .MuiPaper-root{
    width: 100% !important;
}
.elink {
    margin-top: 12px !important;
    margin-left: 40px !important;
}
.sharebyUrlBox {
    height: 100%;
    position: relative;
    padding: 0 10px 0;
    background-color: transparent !important;
}
.inMobLink{
    width: 70%;
    margin: auto;
    padding-top: 5px;
}

.newDesignsd .qrcode{
    margin: 10px !important;
    margin-top: 10px  !important;
}
.newHeader .boardNameInput{
    max-width: 10ch !important;
}
}


.newDrawer #menuBar{
    max-height: inherit !important;
    height: inherit !important;
    padding: 0 0 10px 0 !important;
}

.newHeader .wcChip , .newHeader  .chip2{
    margin: 0px !important;
    padding: 0px !important;
}

.newHeader .rtSideNew{
    margin: auto !important
}
.codePill{
    display: flex;
    padding: 6px;
    align-items: center;
    margin-left: 8px;
}

 
.newTopHeader{
    left: 16px;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.newDrawer .longMenuDiv{
    height: 100%;
}

.pgNavNew{
    width: 100%;
    height: fit-content;
    margin: 0 45px;
    text-align: center;
    position: relative !important;
}

.ms-Icon{
    font-size: 24px;
}

.undoNewCls{
    margin-left: 0px !important;
    padding: 0 0 0 55px;
    background: white;
    width: 100%;
    left: 10px;
    z-index: 999 !important;
    top: 50px !important;
    box-shadow: 0px 0px 8px 2px rgba(13, 20, 22, 0.02);
}

.newHeader .boardNameInput{
    max-width: 40ch;
}


#BookManage .MuiMenu-paper{
    top: 50px !important;
}

.customNewMenuItem, .customNewMenuItem .MuiTypography-h6{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #40537B !important;
}

.formcontrol {
    display: flex;
    flex-direction: column;
}
.formcontrol .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3174F5;
}
.unorderList {
    margin: 5px 5px;
    padding: 6px !important;
    list-style-position: inside;
}
.TileChkBoxes .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3174F5;;
}



.newDrawer #menuBar{
    max-height: inherit !important;
    height: inherit !important;
    padding: 0 0 10px 0 !important;
}

.newHeader .wcChip , .newHeader  .chip2{
    margin: 0px !important;
    padding: 0px !important;
}

.newHeader .rtSideNew{
    margin: auto !important
}
.codePill{
    display: flex;
    padding: 6px;
    align-items: center;
    margin-left: 8px;
}


.p-0{
    padding: 0 !important;
}
.customNewMenuItem, .customNewMenuItem .MuiTypography-h6{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #40537B !important;
}

.MuiList-padding{
    padding: 0 !important;
}

.moveHeader{
    position: relative !important;
    left: 190px !important;
    background-color: white;
}
.customNewMenuItem{
    height: 25px;
    margin: 4px auto !important;
}

.my-10{
    margin: 0 10px;
}

.newDrawer #menuBar .MuiListItem-root{
    margin: 12px 0 !important;
}


.newSliderHeader{
    cursor: pointer;
    width: fit-content;
    position: relative;
    display: flex;
    justify-content: space-evenly;
}

.newSliderHeader .color-control{
    position: relative !important;
    top: 0;
    left: 0;
}

.newSliderHeader .MuiSvgIcon-root {
    fill: #7D8FB6;
}
.newSliderHeader .toolIcon {
    color: #7D8FB6 !important;
}
.dropdownStyle ul{
    height: calc(100vh - 170px);
}

.selectFam .MuiInput-underline:before, .selectFam .MuiInput-underline:after{
border: none !important;
}

.selectFam .MuiSelect-select:focus{
    background-color: transparent !important;
}

.fsPopper .MuiPopover-paper{
    height: 220px !important;
    overflow: hidden;
    padding: 50px 0 !important;
    width: 40px;
    display: flex;
    justify-content: center;
    background-color: transparent !important;
    top: 50px !important; 
    box-shadow: none !important;
}


.fsPopper .MuiSlider-track, .fsPopper .MuiSlider-rail, .fsPopper .MuiSlider-thumb{
    color: rgb(125, 143, 182);
}

.fsPopper .MuiSlider-thumb{
    background-color: rgb(125, 143, 182);
}

.font12-lato, .selectFam h6{
    font-family: Lato !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 100% !important;
    color: #40537B !important;
}


.font12-lato, .selectFam ul li h6{
    font-family: Lato !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 100% !important;
    color: #FFFFFF !important;
}
 
.selectFam{
    margin: auto;
}

.gIcon{
    margin: 0 10px;
    width: 18px;
}
.dashed .MuiIconButton-label{
    border: 1px dashed !important;
    padding: 2px;
    width: fit-content;
}
.newSliderHeader button{
    padding: 0;
    margin: 5px;
    border-radius: 0%;
}
.newSliderHeader button:hover{
    color: #7D8FB6;
}

.newDrawer #menuBar .MuiListItem-root:last-child{
    margin-bottom: 30px !important;
}
.newCG{
    top: 140px !important;
}

.goheader{
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 98px;
    z-index: 11;
}

.sets{
    display: flex;
    align-items: center;
    margin-left: 55px;
}
.sets button{
    margin: 0 10px;
}

.lato10px{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #526A9E;
}
.trashMenu{
    padding: 0px 2px !important;
    border-radius: 0% !important;
    min-width: 4px;
}


.bTypeLbl{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #7D8FB6;
}

.inpUtube{
    border-right: 1px solid #F0F2F4 !important;
    border-left: 1px solid #F0F2F4 !important;
    padding: 0 3px !important;
}

.pgNavNew button{
    width: fit-content;
}


#windowHeader .MuiInput-underline:before, #windowHeader .MuiInput-underline:after{
    border: none !important;
}
.ReactPiano__Keyboard{
    height: 100% !important;
    width: 100% !important;
}

.cIcon{
    height: 10px;
}
.m2px{
    margin: 2px;
}

.openDHeader{
    padding: 0 0 0 24px!important;
}

.openDpagNav{
    left: 65% !important;
}

.togglePill{
    top: 100px !important;
    left: -40px !important;
}
.z1{
    z-index: 1 !important;
}
.customRadioBtns .MuiRadio-colorSecondary.Mui-checked, .customSwtBtns .MuiSwitch-colorSecondary.Mui-checked{
 color: #612AFF !important;
}

.customSwtBtns .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #6B51B8 !important;
   }

 .caofBoard{
     display: block !important;
     padding: 0 !important;
 }  

 .dividerBorder{
    border-top: 1px solid #E0E5E7;
    margin-top: 20px;
 }

 .doneBtnBB{
     margin: 10px 0 0 0 !important;
     padding: 20px 25px 10px;
 }

 .doneBtnBB button{
     width: 79px;
     height: 32px;
     color: #fff;
     background: #612AFF;
     padding: 0px 24px;
     border-radius: 999px;
 }


 .doneBtnBB .MuiButton-root:hover{
    background-color: #612AFF !important;
    color: #fff !important;
 }


 .tabs button{
     min-width: 30px;
 }

 .togBtnGrp{
    margin: 15px auto;
    background-color: #f3f8fe !important;
    width: 80%;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 0px 8px rgb(0 0 0 / 16%);
    border-radius: 999px;
    min-height: 35px;
    display: flex;
    align-items: center;
 }
 .togBtnGrp div{
        min-width: 40px ;
        border-radius: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
 }

 .togBtnGrp span{
    color: white;
    margin: 0 9px;
}

.togBtnGrpPad{
    padding: 9px 12px;
    cursor: default !important;
}

 .switch-ios.switch-toggle a{
    background: #612AFF !important;
    border-radius: inherit;
 }

 .switch-ios.switch-toggle {
    border: 1px solid #D8D9DB;
    border-radius: 30px;
    background-color: #fff;
}

.switch-ios input:checked + label{
    color: #fff !important;
}

:focus-visible {
    outline: transparent;
}

.switch-toggle input:focus ~ span a, .switch-toggle input:focus + label {
    outline: transparent;
}
.switch-toggle input + label{
    width: fit-content;
}
.iconWd{
    width: 17px;
}

.tabs button{
    color: #612AFF;
    fill: #612AFF;
}

.tabs .MuiTabs-indicator{
    background-color: #612AFF !important;
}

.sidebar{
    top: 120px !important;
    border-radius: 8px;
    height: calc(100% - 170px) !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 1%), 0px 0px 5px rgb(0 0 0 / 21%);
}
*::-webkit-scrollbar-track{
    background: transparent !important;
}

.btnSqr{
    border-radius: 0 !important;
}

.p-5{
    padding: 5px !important;
}

#simple-menu ul li h6 , .longMenuDiv ul .MuiTypography-h6{
    font-family: Lato !important;
    font-style: normal !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 100% !important;
    color: #FFFFFF !important;
}
.palBtn{
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E6E9;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 8px 2px rgb(13 20 22 / 2%);
    text-align: center;
    }

    .palBtn .MuiSelect-select{
    padding-right: 11px !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'ss04' on;
    color: #40537B;
    padding: 14px 20px !important;
    }
    .palBtn .MuiSelect-select:focus{
    background-color: transparent !important;
    }

    .palBtn .MuiSelect-icon{
    top: 10px;
    right: 10px;
    }

    .searchBar{
    display: flex;
    justify-content: center;
    }
    .searchBar .MuiInputBase-root{
    border-radius: 50px;
    }
    
    .searchBar .MuiFormControl-root{
        width: 80%;
        background-color: #f3f8fe !important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 0px 8px rgb(0 0 0 / 16%);
        border-radius: 999px;
    }

    .switch-ios.switch-toggle label{
        text-transform: capitalize;
    }

    .searchField .MuiFormControl-root{
        width: 78%;
        margin: auto;
        background-color: #f3f8fe !important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 0px 8px rgb(0 0 0 / 16%);
        border-radius: 999px;
    }

.footerBar{
        position: fixed;
        bottom: 0px;
        z-index: 11;
        width: 100%;
        background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F5 100%);
        box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 0px 8px rgb(0 0 0 / 16%);
        overflow: hidden;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .footerNavlbl{
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 12px !important;
        line-height: 100% !important;
        display: flex !important;
        align-items: center !important;
        font-feature-settings: 'ss04' on !important;
        color: #7D8FB6;
        margin: 0 5px 0 10px;
        text-transform: capitalize;
    }

    .footerBtn{
        border-right: 1px solid #BCC5DA;
        box-sizing: border-box;
        margin: 0  0 0 8px!important;
        height: 40px;
        display: flex;
        align-items: center;
        min-width: 120px;
        justify-content: space-around;
    }

    .disp-fc-hf{
        height: 100%;
        display: flex;
        align-items: center;
    }

    .activeTabFooter{
        background: #FFFFFF;
        border: 1px solid #BCC5DA;
        box-sizing: border-box;
        margin: 0px !important;
        padding: 6px;
        border-left: 0;
    }
    .colorPickerCircle button{
        padding: 8px;
        min-width: 10px;
        margin: 2px;
    }
    .colorPickerCircle .MuiTouchRipple-root{
        height: 10px;
        width: 10px;
    }
    .newDNavi button{
        margin: 0 !important;
    }
    .pgNavThumb{
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 10px;
    }

/* mobile and tablet */
@media (max-width: 820px) {

    .newHeader .boardNameInput{
        max-width: 13ch;
    }
    .respMode-pl{
        padding: 0 10px 0 50px !important;
    }
    .pgNavThumb{
        margin-left: 0px;
    }
    .footerBtn{
        width: inherit;
    }
    .chip2Overflow{
        width: 230px;
        overflow: hidden;
        overflow-x: scroll;
    }
}

.pal_icons{
    width: fit-content;
    margin: 18px auto;
}
.pal_icons svg{
    cursor: pointer;
    fill: #7D8FB6;
}
.overFlowList{
    height: 100%;
}
.paletContainer{
    min-width: 320px;
    position: relative;
}
.closeImgContainer{
    position: absolute !important;
    right: 0;
}

.closeImgContainer .MuiIconButton-label{
    z-index: 11;
}
/* .closeImgContainer img{
    position: relative !important;
    top: 10px !important;
    cursor: pointer;
} */
.dragImgContainer img{
    position: absolute;
    top: 0%;
    width: 28px;
    user-select: none;
}

.newDragPalette{
    background: #FFFFFF;
border: 1px solid #E2E6E9 !important;
box-sizing: border-box;
box-shadow: 0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02);
border-radius: 4px !important;
}

.overFlowList ul li button{
    width: 100%;
    text-align: left;
}
.paletContainer .formSelect .MuiOutlinedInput-input{
    padding: 9px 20px !important;
} 

.paletContainer .formSelect .MuiSelect-iconOutlined {
    right: 20px;
    top: 8px;
}

.dynamicbtn{
    width: fit-content !important;
}

.lato14pxBold{
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 100% !important;
    display: flex !important;
    align-items: center !important;
    color: #40537B !important;
}

.modalHeader{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boardCPdragDiv {
    border: 0.5px solid rgba(0, 0, 0, 0.08) !important;
    box-sizing: border-box;
    background-color: #FFFFFF !important;
}

.dragImgContainer{
    padding: 6px;
}

.sidbarClosedCls{
    left: 80px !important;
    right: 38px !important;
}
.cnvContainer{
    position: fixed;
    top: 120px;
    bottom: 40px;
    width: 100%;
    overflow: scroll;
}
.wbcjrCnvCont{
    top: 0 !important;
    bottom: 0 !important;
}
.sidbarOpenedCls{
    left: 294px !important;
}

.colorPickerCircle{
    margin: 0 5px;
}

.newbtnStyle{
    color: #FFFFFF !important;
    border: 1px solid #C8CEDA !important;
    margin: 8px 8px 8px 0px !important;
    display: inline-flex !important;
    font-size: 16px !important;
    box-shadow: 0px 1px 2px rgb(0 35 61 / 4%) !important;
    font-family: Lato !important;
    font-weight: 600 !important;
    border-radius: 999px !important;
    letter-spacing: 0.02em !important;
    text-transform: none !important;
    height: fit-content;
    min-width: 100px !important;
}

#undoRedoPanel{
    display: flex;
}
.newDNavi{
    flex-wrap: nowrap !important;
}
.py-20{
    padding: 0 20px !important;
}
.movePosBorder{
    bottom: 45px;
    right: 48px !important;
}

.newDrawer{
    border-radius: 0% !important;
}

.liveWebinarTitle{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #3174F5;
}

.webinarPill{
    border: 1px solid #3174f5;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    width: fit-content;
    cursor: pointer;
}

.liveWebinarTitle img{
    padding: 0 10px;
}

.textDecorNone{
    text-decoration: none !important;
}

.rightSideHeader{
    justify-content: space-between;
}

.labelsHeader{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #5D5770;
}
.labelBlue{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #214DA3;
}

.my-20{
    margin: 0 20px !important;
}
.formPanel2 {
    height: 250px;
    overflow: auto;
}
.rightArr{
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    cursor: pointer;
}
.showTeaCls{
    width: 88% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.showTeaCls svg{
    fill: rgba(0, 0, 0, 0.54);
    margin: 10px;
}
.menuLabel{
    opacity: 1 !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 13px !important;
    line-height: 100% !important;
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
    color: #40537B !important;
}

.btBorder{
    border-bottom: 1px solid !important;
}

.MuiMenuItem-root{
    font-family: Lato !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 100% !important;
    color: #40537B !important;
}
.mx-20px{
    margin-top: 20px;
    margin-bottom: 20px;
}
 
.overflowAuto{
    overflow: auto;
}
.closingButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid #E2E6E9
}
.wbcSidebarCls{
    left: 0 !important;
}@media (max-width: 1080px) {
    .chip2Overflow{
        width: 220px;
        overflow: hidden;
        overflow-x: scroll;
    }
}
.selectFam .MuiSelect-icon {
  margin-top: 3px !important
}
textarea::-webkit-resizer {
    border-width: 8px;
    border-style: solid;
    border-color: transparent #3174f5 #3174f5 transparent;
  }